<template>
  <div>
    <md-table md-card table-header-color="blue" :value="skillLevels">
      <md-table-toolbar>
        <div class="md-toolbar-section-start">
          <h1 class="md-title">Уровни скилла</h1>
        </div>
      </md-table-toolbar>

      <md-table-empty-state md-label="Список пуст" />

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Уровень">
          {{ item.level }}
        </md-table-cell>

        <md-table-cell md-label="Значение">
          {{ item.value }}
        </md-table-cell>

        <md-table-cell md-label="Является прогрессирующим">
          {{ !!item.isProgressiveSkill ? "Да" : "Нет" }}
        </md-table-cell>

        <md-table-cell md-label="Является процентным">
          {{ !!item.isPercentage ? "Да" : "Нет" }}
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    skillId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapState("skills", ["skillLevels"]),
  },

  async mounted() {
    await this.getSkillLevels(this.skillId);
  },

  methods: {
    ...mapActions("skills", ["getSkillLevels"]),
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .md-table-cell-container {
    white-space: nowrap;
  }
}
</style>
